import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import normalFetalHR from './images/normalFetalHR.gif';
import slowFetalHR from './images/slowFetalHR.gif';
import intestinalPeristalsis from './images/intestinalPeristalsis.gif';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{marginLeft: "24px",}}>
        Ultrasound examples
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Ultrasound Examples
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
            <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Normal</span>
            </Grid>
            <Grid item xs={12}>
            <img src={normalFetalHR} style={{borderRadius:"8%", maxWidth: "96%"}}/>
            </Grid>
            <Grid item xs={12}>
            <div style={{textAlign: "left", paddingLeft: "8px", maxWidth: "480px", margin: "auto"}}>
            This fetuses heartrate was &ge;200 bpm.  Mom presented happy and healthy towards the end of gestation for pregnancy radiographs.
            </div>
            </Grid>
          </Grid>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
          <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Slow Fetal Heart Rate</span>
          </Grid>
          <Grid item xs={12}>
          <img src={slowFetalHR} style={{borderRadius:"8%", maxWidth: "96%"}}/>
          </Grid>
          <Grid item xs={12}>
          <div style={{textAlign: "left", paddingLeft: "8px", maxWidth: "480px", margin: "auto"}}>
          Mom presented with dark green vaginal discharge. This fetal heart rate is &le;160 bpm and needed to be delivered by caesarian section.  The breeding date was unknown, but there was fetal intestinal peristalsis noted on ultrasound.
          </div>
          </Grid>
          </Grid>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
          <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Intestinal Peristalsis</span>
          </Grid>
          <Grid item xs={12}>
          <img src={intestinalPeristalsis} style={{borderRadius:"8%", maxWidth: "96%"}}/>
          </Grid>
          <Grid item xs={12}>
          <div style={{textAlign: "left", paddingLeft: "8px", maxWidth: "480px", margin: "auto"}}>
          Fetal intestinal peristalsis can be visualized by ultrasound at approximately 62-64 days.
          </div>
          </Grid>
          </Grid>
          </Paper>
          </Grid>
        </Grid>

      </Dialog>
    </div>
  );
}
