import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import anesthesiaPic from './images/anesthesia.jpg';
import behaviorPic from './images/behavior.jpg'
import dentistryPic from './images/dentistry.jpg'
import dermatologyPic from './images/derm.jpg'
import emergencyPic from './images/emergency.jpg'
import euthanasiaPic from './images/euth.jpg'
import internalMedPic from './images/im.jpg'
import microbioPic from './images/microbio.jpg'
import oncologyPic from './images/onco.jpg'
import physicalRehabPic from './images/rehab.jpg'
import preventativePic from './images/preventative.jpg'
import reproductionPic from './images/repro.jpg'
import ultrasoundPic from './images/ultrasound.jpg'

import './App.css';

const searchLabelsPathsTerms = [
  ["Anaphylaxis & Hypersensitivity Reactions", "/anaphylaxis", "anaphylaxis allergic reaction type 1 hypersensitivity reaction insect bite vaccine reaction"],
  ["Blocked Cat (Feline Urethral Obstruction)", "/blocked-cat", "blocked cat urinary tract feline urethral obstruction FLUTD"],
  ["Canine Parvovirus (CPV)", "/parvo", "Canine Parvovirus CPV"],
  ["Cardiopulmonary Resuscitation (CPR)", "/cpr", "cpr cardiopulmonary resuscitation rescue"],
  ["Congestive Heart Failure (CHF)", "/chf", "chf congestive heart failure"],
  ["Diabetic Ketoacidosis (DKA)", "/dka", "dka diabetes diabetic ketoacidosis"],
  ["Dystocia & C-Section", "/dystocia", "dystocia c-section c section caesarian"],
  ["Head Trauma", "/head-trauma", "head trauma mcgs modified glascow coma scale"],
  ["Intervertebral Disc Disease (IVDD)", "/ivdd", "intervertebral disk disease intervertebral disc disease ivdd neck pain back pain"],
  ["Seizures & Status Epilepticus", "/seizures", "seizures status epilepticus"],

]

class QuickSheets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      filteredRoutes: searchLabelsPathsTerms,
      openDialog: true
    }
  }

  filterRoutes = (q) => {
    let filtered = searchLabelsPathsTerms.filter(r => r[2].toLowerCase().includes(q.toLowerCase()));
    this.setState({
      filteredRoutes: filtered
    })
  }

  handleClose = () => {
    this.setState({
      openDialog: false
    })
  }

  termSearch = (e) => {
    this.setState({
      query: e.target.value
    })
    this.filterRoutes(e.target.value)
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="topper">
            <h1> Veterinary <br /> Clinical Quick Sheets</h1>
          </div>
        </header>
        <Dialog
        open={this.state.openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"We've moved!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We have moved this content and more to <a href="https://dogscatsmedicine.com/">DogsCatsMedicine.com</a> which includes 38 Quick Sheets and lots more content! Please join us there!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
        <div className="Projects">
          <img src={loginPic} alt="dog or cat sketch" />
          <input
            style={{backgroundColor: "#fff", textAlign:'center', fontFamily: "Open Sans Condensed, Verdana, sans-serif", border: "1px solid #414344", margin:'20px', borderRadius: "2px", fontSize: "2rem"}}
            onChange={ this.termSearch }
            type='text'
            role='form'
            aria-labelledby='search'
            tabIndex="0"
            placeholder="SEARCH"
            value={ this.state.query }
          />
          {this.state.filteredRoutes.map( r =>
            <Link className="LinkButton" target="" rel="noopener noreferrer" to={r[1]} key={r[1]}>{r[0]}</Link>
          )}
          <br />
        </div>
      </div>
    );
  }
}

const loginPics = [anesthesiaPic, behaviorPic, dentistryPic, dermatologyPic,
  emergencyPic, euthanasiaPic, internalMedPic,
  microbioPic, oncologyPic, physicalRehabPic, preventativePic, reproductionPic, ultrasoundPic]

const loginPic = loginPics[Math.floor(Math.random()*Math.floor(13))]

export default QuickSheets;
