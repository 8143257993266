import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';
import '../App.css';



const styles = theme => ({
  root: {
    fontFamily: 'Noto Sans JP',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
});

class CongestiveHeartFailureQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.twelvehundred}>
          <div className={classes.nintysix}>
            <h1 className="calcH1">Cardiopulmonary Resuscitation (CPR)</h1>
            <h2 className="calcH1">Clinical Quick Sheet</h2>

            <Grid container spacing={0} style={{textAlign: "left"}}>

              <Grid item xs={12}>
               <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                  />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{endAdornment: <InputAdornment>lbs</InputAdornment>,}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="weightkg"
                    type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                    label="Weight (kg)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{endAdornment: <InputAdornment>kg</InputAdornment>,}}
                    />
                </Grid>

                <Grid item xs={12}>
                  <Paper>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" style={{fontWeight: '700'}}>Drug (concentration; dose)</TableCell>
                          <TableCell align="left" style={{fontWeight: '700'}}>Volume (mL)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{backgroundColor: '#e6f7ff'}}>
                          <TableCell align="left">Epi Low (1 mg/mL; 0.01 mg/kg)</TableCell>
                          <TableCell align="left">{(this.state.weightkg*(0.01)/(1)).toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor: '#b3e7ff'}}>
                          <TableCell align="left">Atropine (0.54 mg/mL; 0.04 mg/kg)</TableCell>
                          <TableCell align="left">{(this.state.weightkg*(0.04)/(0.54)).toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor: '#e6ffe6'}}>
                          <TableCell align="left">Naloxone (0.4 mg/mL; 0.04 mg/kg)</TableCell>
                          <TableCell align="left">{(this.state.weightkg*(0.04)/(0.4)).toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor: '#ccffcc'}}>
                          <TableCell align="left">Flumazenil (0.1 mg/mL; 0.01 mg/kg)</TableCell>
                          <TableCell align="left">{(this.state.weightkg*(0.01)/(0.1)).toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor: '#e6ffe6'}}>
                          <TableCell align="left">Atipamezole (5 mg/mL; 100 ug/kg)</TableCell>
                          <TableCell align="left">{(this.state.weightkg*(0.1)/(5)).toFixed(2)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                <span style={{fontWeight: '700'}}>Start CPR immediately in any unresponsive patient that is not breathing.</span> CPR is low risk to patients NOT in cardiopulmonary arrest, and these patients will usually respond to the stimulation of CPR.  Other personnel can simultaneously assess the patient or (if alone) you can reassess after an initial treatment cycle (2 minutes) of CPR.
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>
                <p style={{backgroundColor: '#fdeded', color: '#5F2120', padding: 14}}>We have moved this content and more to <a href="https://dogscatsmedicine.com/">DogsCatsMedicine.com</a> which includes 38 Quick Sheets and lots more content! Please join us there!</p>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

                <Grid item xs={12}>
                  <span style={{textDecoration: 'underline', fontSize: '1.2em'}}>Basic Life Support</span> - 2 minute cycles of uninterrupted chest compressions and ventilation
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <span style={{fontWeight: '700'}}>Chest Compressions</span>
                </Grid>

                <Grid item xs={12}>
                <ul>
                  <li>
                  100-120/min
                  </li>
                  <li>
                  1/3 to 1/2 chest width, allow full elastic recoil of the chest
                  </li>
                  <li>
                  Change person doing compressions every 2 minutes
                  </li>
                </ul>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                <span style={{fontWeight: '700'}}>Ventilation</span>
                </Grid>

                <Grid item xs={12}>
                <ul>
                  <li>
                  Intubation in lateral recumbency with simultaneous compressions
                  </li>
                  <li>
                  10 breaths/min (1 breath every 6 seconds)
                  </li>
                  <li>
                  10 mL/kg with inspiratory time of 1 second
                  </li>
                </ul>
                </Grid>

                <Grid item xs={12} style={{textDecoration: 'underline', fontSize: '1.2em'}}>
                  Advanced Life Support
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Initiate Monitoring</span> - ECG, EtCO<sub>2</sub>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Obtain Vascular Access</span>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary style={{padding: '0px 16px 0px 0px'}} expandIcon={<ExpandMoreIcon />}>
                    <Grid item xs={12}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Administer Reversals</span>
                    </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left" style={{fontWeight: '700'}}>Drug (concentration; dose)</TableCell>
                                  <TableCell align="left" style={{fontWeight: '700'}}>Volume (mL)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow style={{backgroundColor: '#e6ffe6'}}>
                                  <TableCell align="left">Naloxone (0.4 mg/mL; 0.04 mg/kg) for opioids</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.04)/(0.4)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#ccffcc'}}>
                                  <TableCell align="left">Flumazenil (0.1 mg/mL; 0.01 mg/kg) for benzodiazepines</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.01)/(0.1)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#e6ffe6'}}>
                                  <TableCell align="left">Atipamezole (5 mg/mL; 100 ug/kg) for &#593;2 agonists</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.1)/(5)).toFixed(2)}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Evaluate Patient and Check ECG</span><br/> 3 paths outlined below. Continue BLS in 2 minute cycles. Repeat this step.
                </Grid>

                <Grid item xs={12}>
                <ol>
                  <li>
                    Most commonly Asystole or Pulseless Electrical Activity (PEA)
                  </li>
                  <li>
                    Less commonly Ventricular Fibrillation (VF) or Pulseless Ventricular Tachycardia (Pulseless VT)
                  </li>
                  <li>
                    Return of Spontaneous Circulation (ROSC)
                  </li>
                </ol>
                </Grid>

                <Grid item xs={12}>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid item xs={12}>
                    <span style={{fontWeight: '700'}}>1. Asystole or PEA</span>
                    </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left" style={{fontWeight: '700'}}>Drug (concentration; dose)</TableCell>
                                  <TableCell align="left" style={{fontWeight: '700'}}>Volume (mL)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow style={{backgroundColor: '#e6f7ff'}}>
                                  <TableCell align="left">Epi Low (1 mg/mL; 0.01 mg/kg) every other BLS cycle x3</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.01)/(1)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#b3e7ff'}}>
                                  <TableCell align="left">Epi High (1 mg/mL; 0.1 mg/kg) for prolonged CPR</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.1)/(1)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#e6f7ff'}}>
                                  <TableCell align="left">Vasopressin (20 U/mL; 0.8 U/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.8)/(20)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#b3e7ff'}}>
                                  <TableCell align="left">Atropine (0.54 mg/mL; 0.04 mg/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.04)/(0.54)).toFixed(2)}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                        <Grid item xs={12}>
                        <ul>
                        <li>
                        <span style={{fontWeight: '700'}}>Low dose Epinephrine and/or Vasopressin</span> <span style={{fontStyle: 'italic'}}>every other</span> BLS cycle
                        </li>
                        <li>
                        Consider <span style={{fontWeight: '700'}}>Atropine</span> <span style={{fontStyle: 'italic'}}>every other</span> BLS cycle
                        </li>
                        <li>
                        With <span style={{fontWeight: '700'}}>prolonged CPA &ge;10 min</span>, consider<br/><ul><li><span style={{fontWeight: '700'}}>high dose Epinephrine</span></li><li><span style={{fontWeight: '700'}}>Bicarbonate therapy</span></li></ul>
                        </li>
                        </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid item xs={12}>
                    <span style={{fontWeight: '700'}}>2. VF or Pulseless VT</span>
                    </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                        <ul>
                        <li>
                        <span style={{fontWeight: '700'}}>Continue BLS, charge defibrillator</span>
                        </li>
                        <li>
                        <span style={{fontWeight: '700'}}>Clear</span> and give <span style={{fontWeight: '700'}}>1 shock</span><br/>
                        <span style={{fontStyle: 'italic'}}>or</span> <span style={{fontWeight: '700'}}>Precordial Thump</span> <span style={{fontStyle: 'italic'}}>if no defibrillator</span>
                        </li>
                        <li>
                        With <span style={{fontWeight: '700'}}>prolonged VF/VT &ge;10 min</span>, consider<br/><ul><li><span style={{fontWeight: '700'}}>Amiodarone</span> <span style={{fontStyle: 'italic'}}>or</span> <span style={{fontWeight: '700'}}>Lidocaine</span></li><li><span style={{fontWeight: '700'}}>Epinephrine / Vasopressin</span> <span style={{fontStyle: 'italic'}}>every other cycle</span></li><li><span style={{fontWeight: '700'}}>Increase defibrillator dose by 50% to max of 10 J/kg</span></li></ul>
                        </li>
                        </ul>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left" style={{fontWeight: '700'}}>Drug (concentration; dose)</TableCell>
                                  <TableCell align="left" style={{fontWeight: '700'}}>Volume (mL)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow style={{backgroundColor: '#e6f7ff'}}>
                                  <TableCell align="left">Epi Low (1 mg/mL; 0.01 mg/kg) every other BLS cycle x3</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.01)/(1)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#b3e7ff'}}>
                                  <TableCell align="left">Vasopressin (20 U/mL; 0.8 U/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.8)/(20)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#eee6ff'}}>
                                  <TableCell align="left">Amiodarone (50 mg/mL; 5 mg/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(5)/(50)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#ddccff'}}>
                                  <TableCell align="left">Lidocaine (20 mg/mL; 2 mg/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(2)/(20)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#ffccb3'}}>
                                  <TableCell align="left">External Biphasic Defib (J; 2-4 J/kg, calculated at 3 J/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(3)).toFixed(0)}</TableCell>
                                </TableRow>
                                <TableRow style={{backgroundColor: '#ffddcc'}}>
                                  <TableCell align="left">External Monophasic Defib (J; 4-6 J/kg, calculated at 5 J/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(5)).toFixed(0)}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid item xs={12}>
                    <span style={{fontWeight: '700'}}>3. ROSC</span>
                    </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <span style={{fontSize: '1.2em'}}><a href="https://onlinelibrary.wiley.com/cms/asset/de50f2c7-88eb-439e-aca9-23dd70a22a4f/vec757-fig-0002-m.jpg" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Post-Cardiac Arrest Care Algorithm</a></span><br/><span style={{fontWeight: "500"}}>from RECOVER Initiative</span>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12}>
                References:
                </Grid>
                <Grid item xs={12}>
                  <ul>
                  <li>
                  <a href="https://onlinelibrary.wiley.com/doi/10.1111/j.1476-4431.2012.00757.x" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Fletcher, Boller, Brainard, et al. RECOVER evidence and knowledge gap analysis on veterinary CPR. part 7: Clinical guidelines. JVECC 2012.</a>
                  </li>
                  </ul>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12}>
                  <p>
                    This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
                  </p>
                  <p>
                    All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
                  </p>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center"}}>
                  <Link className="" to="/"><i className="material-icons home-button" style={{marginTop: "5px",}}>home</i></Link>
                </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }


};

export default withStyles(styles)(CongestiveHeartFailureQS);
