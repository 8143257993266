import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

import { withStyles } from '@material-ui/core/styles';
import '../App.css';



const styles = theme => ({
  root: {
    fontFamily: 'Noto Sans JP',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
    tablePaper: {
    width: '100%',
    overflowX: 'auto',
  },
});

class BlankQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      dehydration: '8',
      switchSevere: false,
      shock: '0',
      fluidrate: '0',
      k: '0',
      kp: '40',
      factor: '70',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handledehydrationChange = this.handledehydrationChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleshockChange = this.handleshockChange.bind(this);
    this.handlefluidrateChange = this.handlefluidrateChange.bind(this);
    this.handlekChange = this.handlekChange.bind(this)
    this.handlekpChange = this.handlekpChange.bind(this)
    this.handlefactorChange = this.handlefactorChange.bind(this)



  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
    handledehydrationChange(event) {
      this.setState({
        dehydration: event.target.value,
      });
  }
    handlekChange(event) {
      this.setState({
        k: event.target.value,
      });
  }
    handlekpChange(event) {
      this.setState({
        kp: event.target.value,
      });
  }
  handlefluidrateChange(event) {
  this.setState({
    fluidrate: event.target.value,
  });
}
  handleshockChange(event) {
    this.setState({
      shock: event.target.value,
    });
}
handlefactorChange(event) {
  this.setState({
    factor: event.target.value,
  });
}
  handleSwitchChange(event) {
    this.setState({
      switchSevere: event.target.checked
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.twelvehundred}>
          <div className={classes.nintysix}>
            <h1 className="calcH1">Fluids</h1>
            <h2 className="calcH1">Clinical Quick Sheet</h2>

            <Grid container spacing={0} style={{textAlign: "left"}}>

              <Grid item xs={12}>
               <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                  />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{endAdornment: <InputAdornment>lbs</InputAdornment>,}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="weightkg"
                    type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                    label="Weight (kg)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{endAdornment: <InputAdornment>kg</InputAdornment>,}}
                    />
                </Grid>

                <Grid item xs={12}>
                <span style={{fontWeight: '700'}}>Lorem:</span> ipsum
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
                  <Grid item>Canine</Grid>
                  <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
                  </Grid>
                  <Grid item>Feline</Grid>
                </Grid>
                  {this.state.switchSevere ?
                    <Grid container spacing={0}>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>IV bolus to restore circulating volume</span>
                      </Grid>

                      <Grid item xs={12}>
                      <ul>
                      <li>
                      Consider a 1/4 shock bolus of 15 mL/kg = {(this.state.weightkg*(15)).toFixed(0)} mL over 20 minutes. Reassess and repeat if needed.
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>IV bolus to restore circulating volume</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <TextField
                        id="dehydration"
                        type="number" value={this.state.dehydration} onChange={this.handledehydrationChange}
                        label="% dehydration"
                        margin="normal"
                        variant="outlined"
                        InputProps={{endAdornment: <InputAdornment>%</InputAdornment>,}}
                        />

                      <ul>
                      <li>
                      (weight<sub>kg</sub>)<sup>0.75</sup>                      <TextField
                                              id="factor"
                                              type="number" value={this.state.factor} onChange={this.handlefactorChange}
                                              label="70-130"
                                              margin="normal"
                                              variant="outlined"
                                              InputProps={{endAdornment: <InputAdornment></InputAdornment>,}}
                                              />
                                              = maintenance fluid needs for most pets; consider increasing the multiplier to 100 or 130 with puppies/kittens, endocrine disease, renal disease, panting.
                      </li>
                      <li>
                      Maintenance rate (exponential formula) = {((this.state.weightkg**(0.75))*130/24).toFixed(0)} mL/hr; Urine losses at &ge; 1 mL/kg/hr = {(this.state.weightkg*(1)).toFixed(0)} mL/hr (often more in DKA patients); Insensible losses at 20 mL/kg/day = {(this.state.weightkg*(20)/24).toFixed(0)} mL/hr; Fluid deficit of {this.state.dehydration}% = {(this.state.weightkg*(this.state.dehydration)*10).toFixed(0)} mL
                      </li>
                      <li>
                      Subtract shock fluids given during initial stabilization
                      </li>
                      <TextField
                        id="shock"
                        type="number" value={this.state.shock} onChange={this.handleshockChange}
                        label="Shock fluids given"
                        margin="normal"
                        variant="outlined"
                        InputProps={{endAdornment: <InputAdornment>mL</InputAdornment>,}}
                        />
                      <li>
                      If correcting {this.state.dehydration}% dehydration over 12-24 hours, appropriate fluid rate ranges from {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/24)+(this.state.weightkg*(1.833))).toFixed(0)} mL/hr to {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/12)).toFixed(0)} mL/hr
                      </li>
                      <li>
                      Account for and add additional losses (such as vomiting and diarrhea) to your fluid plan
                      </li>
                      <li>
                      If no concurrent heart disease, consider correcting {this.state.dehydration}% dehydration over 6 hours; appropriate fluid rate may approach {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/6)).toFixed(0)} mL/hr
                      </li>
                      </ul>
                      </Grid>
                      </Grid>

                :
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Restore circulating volume</span>
                  </Grid>

                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Consider a 1/4 shock bolus of 22 mL/kg = {(this.state.weightkg*(22)).toFixed(0)} mL over 20 minutes. Reassess and repeat if needed.
                  </li>
                  <TextField
                    id="dehydration"
                    type="number" value={this.state.dehydration} onChange={this.handledehydrationChange}
                    label="% dehydration"
                    margin="normal"
                    variant="outlined"
                    InputProps={{endAdornment: <InputAdornment>%</InputAdornment>,}}
                    />
                  <li>
                  Maintenance rate (exponential formula) = {((this.state.weightkg**(0.75))*130/24).toFixed(0)} mL/hr; Urine losses at &ge; 1 mL/kg/hr = {(this.state.weightkg*(1)).toFixed(0)} mL/hr (often more in DKA patients); Insensible losses at 20 mL/kg/day = {(this.state.weightkg*(20)/24).toFixed(0)} mL/hr; Fluid deficit of {this.state.dehydration}% = {(this.state.weightkg*(this.state.dehydration)*10).toFixed(0)} mL
                  </li>
                  <li>
                  Subtract shock fluids given during initial stabilization
                  </li>
                  <TextField
                    id="shock"
                    type="number" value={this.state.shock} onChange={this.handleshockChange}
                    label="Shock fluids given"
                    margin="normal"
                    variant="outlined"
                    InputProps={{endAdornment: <InputAdornment>mL</InputAdornment>,}}
                    />
                  <li>
                  If correcting {this.state.dehydration}% dehydration over 12-24 hours, appropriate fluid rate ranges from {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/24)+(this.state.weightkg*(1.833))).toFixed(0)} mL/hr to {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/12)).toFixed(0)} mL/hr
                  </li>
                  <li>
                  Account for and add additional losses (such as vomiting and diarrhea) to your fluid plan
                  </li>
                  <li>
                  If no concurrent heart disease, consider correcting {this.state.dehydration}% dehydration over 6 hours; appropriate fluid rate may approach {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/6)).toFixed(0)} mL/hr
                  </li>
                  </ul>
                  </Grid>

                </Grid>

                }

                <Grid item xs={12}>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Potassium
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Serum K<sup>+</sup> (mEq/L)</TableCell>
                                  <TableCell align="left">Supplemental K<sup>+</sup> in fluids at maintenance rate</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center">3.5 - 5</TableCell>
                                  <TableCell align="left">20</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">3.0 - 3.4</TableCell>
                                  <TableCell align="left">30</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">2.5 - 2.9</TableCell>
                                  <TableCell align="left">40</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">2 - 2.4</TableCell>
                                  <TableCell align="left">60</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">&le;1.9</TableCell>
                                  <TableCell align="left">80 - 100</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">Maximum</TableCell>
                                  <TableCell align="left">0.5 mEq K<sup>+</sup>/kg/hr</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid item xs={12}><span style={{textDecoration: 'underline'}}>K</span><sup>+</sup><sub>max</sub> <span style={{textDecoration: 'underline'}}>Calculator</span></Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <TextField
                                  id="fluidrate"
                                  type="number" value={this.state.fluidrate} onChange={this.handlefluidrateChange}
                                  label="Fluid Rate"
                                  margin="normal"
                                  variant="outlined"
                                  InputProps={{endAdornment: <InputAdornment>mL/hr</InputAdornment>,}}
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                  <TextField
                                    id="k"
                                    type="number" value={this.state.k} onChange={this.handlekChange}
                                    label="K+"
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{endAdornment: <InputAdornment>mEq/L</InputAdornment>,}}
                                    />
                              </Grid>
                              <Grid item xs={12}>
                                With {this.state.k} mEq/L supplemental K<sup>+</sup> at {this.state.fluidrate} mL/hr <span style={{backgroundColor: '#fff2ac'}}>you are administering {((this.state.k)/1000*(this.state.fluidrate)/(this.state.weightkg)).toFixed(2)} mEq K<sup>+</sup>/kg/hr</span>.  If this is greater than 0.5 mEq K<sup>+</sup>/kg/hr, you are in the danger zone.
                              </Grid>
                              <Grid item xs={12}>
                              <ul>
                              <li>
                              Remember, many fluids already have some amount of K<sup>+</sup> (such as LRS and Phylyte)
                              </li>
                              <li>
                              Consider using 2 fluid pumps with the second running at maintenance to correct electrolytes
                              </li>
                              </ul>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>


                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Fluid comparison table
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Paper>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Solution</TableCell>
                                <TableCell align="center">Na<sup>+</sup></TableCell>
                                <TableCell align="center">Cl<sup>-</sup></TableCell>
                                <TableCell align="center">K<sup>+</sup></TableCell>
                                <TableCell align="center">Mg<sup>++</sup></TableCell>
                                <TableCell align="center">glu (g/L)</TableCell>
                                <TableCell align="center">mOsm/L</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="left">0.9% Saline</TableCell>
                                <TableCell align="center">154</TableCell>
                                <TableCell align="center">154</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">308</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">LRS</TableCell>
                                <TableCell align="center">130</TableCell>
                                <TableCell align="center">109</TableCell>
                                <TableCell align="center">4</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">273</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">Phylyte</TableCell>
                                <TableCell align="center">140</TableCell>
                                <TableCell align="center">98</TableCell>
                                <TableCell align="center">5</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">294</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">Normosol-R</TableCell>
                                <TableCell align="center">140</TableCell>
                                <TableCell align="center">98</TableCell>
                                <TableCell align="center">5</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">296</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">Plasmalyte 148</TableCell>
                                <TableCell align="center">140</TableCell>
                                <TableCell align="center">98</TableCell>
                                <TableCell align="center">10</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">269</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">Normosol-M</TableCell>
                                <TableCell align="center">40</TableCell>
                                <TableCell align="center">40</TableCell>
                                <TableCell align="center">13</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">50</TableCell>
                                <TableCell align="center">364</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">Plasmalyte 56</TableCell>
                                <TableCell align="center">40</TableCell>
                                <TableCell align="center">40</TableCell>
                                <TableCell align="center">13</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">111</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">0.45% Saline</TableCell>
                                <TableCell align="center">77</TableCell>
                                <TableCell align="center">77</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">154</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">0.45% Saline + 2.5% dex</TableCell>
                                <TableCell align="center">77</TableCell>
                                <TableCell align="center">77</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">0</TableCell>
                                <TableCell align="center">25</TableCell>
                                <TableCell align="center">280</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          </Paper>
                        </Grid>
                        <Grid item xs={12}>
                          <ul>
                            <li>
                              lorem
                            </li>
                            <li>
                              lorem
                            </li>
                            <li>
                              lorem
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                      References:
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <p><sup>1</sup> lorem</p>

                        <p><sup>2</sup> lorem</p>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <p>
                    This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
                  </p>
                  <p>
                    All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
                  </p>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center"}}>
                  <Link className="" to="/"><i className="material-icons home-button" style={{marginTop: "5px",}}>home</i></Link>
                </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }


};

export default withStyles(styles)(BlankQS);
