import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

import { withStyles } from '@material-ui/core/styles';
import '../App.css';



const styles = theme => ({
  root: {
    fontFamily: 'Noto Sans JP',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
    tablePaper: {
    width: '100%',
    overflowX: 'auto',
  },
});

class BlankQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      switchSevere: false,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
  handleSwitchChange(event) {
    this.setState({
      switchSevere: event.target.checked
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.twelvehundred}>
          <div className={classes.nintysix}>
            <h1 className="calcH1">Lorem</h1>
            <h2 className="calcH1">Clinical Quick Sheet</h2>

            <Grid container spacing={0} style={{textAlign: "left"}}>

              <Grid item xs={12}>
               <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                  />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{endAdornment: <InputAdornment>lbs</InputAdornment>,}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="weightkg"
                    type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                    label="Weight (kg)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{endAdornment: <InputAdornment>kg</InputAdornment>,}}
                    />
                </Grid>

                <Grid item xs={12}>
                <span style={{fontWeight: '700'}}>Presentation:</span> lorem
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
                  <Grid item>StateA</Grid>
                  <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
                  </Grid>
                  <Grid item>StateB</Grid>
                </Grid>
                  {this.state.switchSevere ?
                    <Grid container spacing={0}>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>B</span>
                      </Grid>
                    </Grid>
                :
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>A</span>
                  </Grid>
                </Grid>

                }

                <Grid item xs={12} style={{textDecoration: 'underline'}}>
                  Diagnostics
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Lorem</span> ipsum
                </Grid>

                <Grid item xs={12}>
                <ul>
                <li>
                Lorem
                </li>
                <li>
                Lorem
                </li>
                <li>
                Lorem
                </li>
                </ul>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Ket/Midz +/- Bup (I.M.)
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Drug (concentration; dose)</TableCell>
                                  <TableCell align="left">Volume (mL)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center">Midazolam (5 mg/mL; 0.3 mg/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.3)/(5)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">Ketamine (100 mg/mL; 5 mg/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(5)/(100)).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">Buprenorphine (0.3 mg/mL; 0.02 mg/kg)</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.02)/(0.3)).toFixed(2)}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                Medications for long-term management:
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <span style={{textDecoration: 'underline'}}>Med</span>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                  MedInstructions
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                <ul>
                <li>
                  DosingInfo <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(1)).toFixed(2)}</span>
                </li>
                </ul>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                  Informational Handouts:
                </Grid>
                <Grid item xs={12}>
                  <span className="LinkBlue"><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951735" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>LinkTitle</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></span>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                  Discharge Note (Key Points):
                </Grid>
                <Grid item xs={12}>
                  <ul>
                  <li>
                    lorem
                  </li>
                  <li>
                    lorem
                  </li>
                  </ul>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                      References:
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <p><sup>1</sup> lorem</p>

                        <p><sup>2</sup> lorem</p>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <p>
                    This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
                  </p>
                  <p>
                    All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
                  </p>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center"}}>
                  <Link className="" to="/"><i className="material-icons home-button" style={{marginTop: "5px",}}>home</i></Link>
                </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }


};

export default withStyles(styles)(BlankQS);
