import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';
import '../App.css';



const styles = theme => ({
  root: {
    fontFamily: 'Noto Sans JP',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
    tablePaper: {
    width: '100%',
    overflowX: 'auto',
  },
});

class BlankQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      switchSevere: false,
      switchFentanyl: false,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleFentanylChange = this.handleFentanylChange.bind(this)

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
  handleSwitchChange(event) {
    this.setState({
      switchSevere: event.target.checked
    });
  }
  handleFentanylChange(event) {
    this.setState({
      switchFentanyl: event.target.checked
    });
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.twelvehundred}>
          <div className={classes.nintysix}>
            <h1 className="calcH1">Canine Parovirus (CPV)</h1>
            <h2 className="calcH1">Clinical Quick Sheet</h2>

            <Grid container spacing={0} style={{textAlign: "left"}}>

              <Grid item xs={12}>
               <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                  />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{endAdornment: <InputAdornment>lbs</InputAdornment>,}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="weightkg"
                    type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                    label="Weight (kg)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{endAdornment: <InputAdornment>kg</InputAdornment>,}}
                    />
                </Grid>

                <Grid item xs={12}>
                <span style={{fontWeight: '700'}}>Presentation:</span> Puppies or incompletely vaccinated adult dogs with fever, depression, vomiting, or bloody diarrhea.  May present in hypovolemic shock with sepsis, DIC, ARDS.
                </Grid>

                <Grid item xs={12}>
                <span style={{fontWeight: '700'}}>Diagnostics:</span> Point of care ELISA is most commonly used and has a high sensitivity and specificity by the time clinical signs are noted in most patients.  Patients with clinical signs highly suggestive of parvovirus that test negative initially may be retested 48 hours later as antigen shedding may lag behind clinical signs in few patients.
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>
                <p style={{backgroundColor: '#fdeded', color: '#5F2120', padding: 14}}>We have moved this content and more to <a href="https://dogscatsmedicine.com/">DogsCatsMedicine.com</a> which includes 38 Quick Sheets and lots more content! Please join us there!</p>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

                <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
                  <Grid item>Inpatient</Grid>
                  <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
                  </Grid>
                  <Grid item>Outpatient</Grid>
                </Grid>
                  {this.state.switchSevere ?
                    <Grid container spacing={0}>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>SNAP Parvo (ELISA)</span> test
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <span style={{fontWeight: '700'}}>Outpatient Protocol</span> can be considered for stable patients (not in hypovolemic shock) when hospitalization is cost-prohibitive.  Once a diagnosis is established, treatments should be prioritized over diagnostics.  Prognosis is variable, but tends to improve with intensive inpatient therapy.
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Rectal temperature</span>
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      Heat support until temp &ge;99F (37.2C)
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Assess visceral pain (common)</span>
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      10-20 ug/kg Buprenorphine = {(this.state.weightkg*(0.01)).toFixed(2)} to {(this.state.weightkg*(0.02)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.01)/0.3)).toFixed(2)} to {((this.state.weightkg*(0.02)/0.3)).toFixed(2)} mL</span> of 0.3 mg/mL Buprenorphine which can be given IV, IM, SC or transmucosally.  Transmucosal buprenorphine can be administered by pet parents every 6-8 hours (although this can become expensive if the injectable form of the medication is prescribed).
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>PCV/TS</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Blood smear</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>BG</span>
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      If BG &le;80 mg/dL, supplement with 1-5 mL Karo syrup every 2-6 hours
                      </li>
                      <li>
                      Encouraged syringe feeding of 1 ml/kg = {(this.state.weightkg*1).toFixed(0)} mL Hill's a/d or Royal Canine Recovery as tolerated every 6 hours.
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Cefovecin (Convenia)</span>
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      8 mg/kg = {(this.state.weightkg*8).toFixed(2)} mg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(8)/(80)).toFixed(2)} mL</span> of 80 mg/mL cefovecin (Convenia) SC for this patient
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Fecal OP + Giardia antigen</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Empirical deworming</span> with Pyrantel Pamoate
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      10 mg/kg = {(this.state.weightkg*10).toFixed(2)} mg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)/(50)).toFixed(2)} mL</span> of 50 mg/mL Pyrantel Pamoate PO for this patient
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Maropitant (Cerenia)</span>
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      1 mg/kg = {(this.state.weightkg*1).toFixed(2)} mg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/(10)).toFixed(2)} mL</span> of 10 mg/mL Maropitant (Cerenia) SC every 24 hours for this patient
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Famotidine</span>
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      1 mg/kg = {(this.state.weightkg*1).toFixed(2)} mg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/(10)).toFixed(2)} mL</span> of 10 mg/mL Famotidine SC every 12-24 hours for this patient
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <Checkbox
                        color="primary"
                        /><span style={{fontWeight: '700'}}>Subcutaneous Fluids</span>
                      </Grid>
                      <Grid item xs={12}>
                      <ul>
                      <li>
                      40-60 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(40)).toFixed(2)} to {(this.state.weightkg*(60)).toFixed(2)} mL</span> of balanced crystaloids every 12-24 hours for this patient
                      </li>
                      </ul>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider style={{margin: '20px 0px'}}/>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider style={{margin: '20px 0px'}}/>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                      Medications to consider for PO administration once vomiting resolves:
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <span style={{textDecoration: 'underline'}}>Maropitant (Cerenia)</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        Give INPUT tablet by mouth every 24 hours as needed to reduce nausea and improve appetite.  This medication has broad anti-inflammatory properties and may help your pet feel better.  Next dose due INPUT.
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <ul>
                      <li>
                        &ge; 2 mg/kg or &ge; {(this.state.weightkg*(2)/(1)).toFixed(2)} mg for this patient
                      </li>
                      <li>
                        Available as 16 mg, 24 mg, 60 mg, 160 mg tablets. Generally dosed &ge;2 mg/kg PO
                      </li>
                      </ul>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <span style={{textDecoration: 'underline'}}>Tumil-K or other oral potassium supplement</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        Give INPUT teaspoon by mouth INPUT times each day. Next dose due INPUT.
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <ul>
                      <li>
                        Generally given at 0.5 to 1 tsp per 10lbs body weight 2-6 times each day.
                      </li>
                      </ul>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <span style={{textDecoration: 'underline'}}>Metronidazole</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        Give INPUT by mouth every 12 hours for INPUT days.  This is an antibiotic that may help treat diarrhea.  Next dose due INPUT.
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <ul>
                      <li>
                        10-15 mg/kg = {(this.state.weightkg*(10)/(1)).toFixed(2)} to {(this.state.weightkg*(15)/(1)).toFixed(2)} mg for this patient.
                      </li>
                      <li>
                        Available as 250 mg or 500 mg tablets or 100 mg/mL oral suspension
                      </li>
                      </ul>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <span style={{textDecoration: 'underline'}}>Capromorelin (Entyce)</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        Give INPUT mL by mouth every 24 hours as need to promote appetite.  This is an appetite stimulant.  Next dose due INPUT.
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <ul>
                      <li>
                        3 mg/kg = {(this.state.weightkg*(3)/(1)).toFixed(2)} mg = {(this.state.weightkg*(3)/(30)).toFixed(2)} mL of 30 mg/mL Entyce for this patient.
                      </li>
                      </ul>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <span style={{textDecoration: 'underline'}}>Panacur (Fenbendazole)</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        Give INPUT by mouth every 24 hours for 5 days to treat intestinal parasites.  Next dose due INPUT.
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <ul>
                      <li>
                        50 mg/kg = {(this.state.weightkg*(50)/(1)).toFixed(1)} mg = {(this.state.weightkg*(50)/(100)).toFixed(1)} mL of 100 mg/mL Fenbendazole for this patient.  This is equivalent to {(this.state.weightkg*(50)/(222)).toFixed(1)} g of 22.2% granules.
                      </li>
                      </ul>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left'}}>
                        <span style={{textDecoration: 'underline'}}>Probiotic</span>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'left'}}>
                      <ul>
                      <li>
                        Consider FortiFlora, Advita, Pro-Pectalin, Proviable, or any similar canine probiotic to support GI health.
                      </li>
                      </ul>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider style={{margin: '20px 0px'}}/>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                        Informational Handouts:
                      </Grid>
                      <Grid item xs={12}>
                        <span className="LinkBlue"><a href="https://petparenthandouts.com/parvovirus" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Parvovirus</a> <span style={{fontWeight: "500"}}>from Pet Parent Handouts</span></span>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="LinkBlue"><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951463" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Parvovirus in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></span>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider style={{margin: '20px 0px'}}/>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                        Discharge Note (Key Points):
                      </Grid>
                      <Grid item xs={12}>
                        <ul>
                        <li>
                          Canine Parvovirus is a serious disease and many patients do not survive.  Outpatient care is associated with a lower chance of survival.  Frequent recheck examinations with a veterinarian (often every 12 to 24 hours) are a necessary part of many outpatient treatment protocols.  Recheck immediately if you have any concern that your dog is worsening.  Inpatient therapy with life-saving IV fluid administration may be necessary.  Even with the most intensive care, some patients with this condition will die.
                        </li>
                        <li>
                          1-5 mL of Karo syrup can be given every 2-6 hours to help prevent low blood sugar. Encouraged (do not force) syringe feeding of {(this.state.weightkg*1).toFixed(0)} mL Hill's a/d or Royal Canine Recovery as tolerated every 6 hours can be attempted.
                        </li>
                        <li>
                          Parvovirus is contagious (can make other puppies and occasionally adult dogs sick), lasts for years in the environment where your dog has pooped or vomited, and will be shed in the poop of your dog for the next several weeks.  Vaccinate any unvaccinated dogs in your home and do not allow unvaccinated dogs on your property in areas where your pet has been.
                        </li>
                        <li>
                          Once recovered, continuing vaccinations and preventative care (including intestinal parasite, heartworm, and flea/tick/mite prevention) is especially important to prevent further health challenges.
                        </li>
                        <li>
                          Recheck promptly if not improving or worsening in any way.  Not eating, vomiting, low energy, diarrhea, difficulty breathing, or pain are all serious concerns that require prompt veterinary attention. Do not hesitate to call or recheck promptly if you have any concerns.
                        </li>
                        </ul>
                      </Grid>
                    </Grid>
                :
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>SNAP Parvo (ELISA)</span> test
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                  <span style={{fontWeight: '700'}}>Inpatient Protocol</span> is associated with greater % survival, is necessary with severe sequalae of CPV including sepsis, DIC, irretractable emesis, uncontrolled hemorrhagic diarrhea, &ge;10% dehydration among other criteria that influence clinical impression.
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>IV fluid resuscitation</span> followed by aggressive rehydration and IV fluid therapy
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(22.5)).toFixed(0)} mL</span> is a 1/4 shock bolus. Give over 15 minutes, then reassess.
                  </li>
                  <li>
                  {(((this.state.weightkg**(0.75))*130)/(24)).toFixed(0)} mL/hr is the maintenance fluid rate for this patient. {(this.state.weightkg*(100)).toFixed(0)} mL given over 6 hours would correct 10% dehydration in an otherwise healthy patient.  Consider {((((this.state.weightkg**(0.75))*70)/(24))+(this.state.weightkg*(0.1)*(1000)/(6))).toFixed(0)} mL for the first 6 hours for patients at approximately 10% dehydration.  Don't forget to add ongoing losses to this fluid plan.  Reassess and adjust as needed.
                  </li>
                  <li>
                  Consider adding 2.5% to 5% dextrose to IVF therapy to help maintain BG.
                  </li>
                  <li>
                  Consider adding 20 mEq KCl to isotonic crystaloid (such as LRS).
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Rectal temperature</span>
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Heat support until temp &ge;99F (37.2C)
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Assess visceral pain (common)</span>
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Consider 5-10 ug/kg Fentanyl IV bolus = {(this.state.weightkg*(5)).toFixed(2)} to {(this.state.weightkg*(10)).toFixed(2)} ug or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.005)/0.05)).toFixed(2)} to {((this.state.weightkg*(0.01)/0.05)).toFixed(2)} mL</span> of 0.05 mg/mL Fentanyl for this patient.
                  </li>
                  <li>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{padding: '0px 16px 0px 0px'}}>
                      <Grid item xs={12}>
                      Consider an opioid CRI or triple drip (FLK or similar).
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container alignItems="center" spacing={0}>
                      <Grid item style={{fontWeight: '700'}}>Fentanyl</Grid>
                      <Grid item><Switch checked={this.state.switchFentanyl} onChange={this.handleFentanylChange} name="Fentanyl" color="primary" />
                      </Grid>
                      <Grid item style={{fontWeight: '700'}}>Hydromorphone</Grid>
                      {this.state.switchFentanyl ?
                      <Grid item xs={12}>
                      <Paper className={classes.tablePaper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow style={{backgroundColor: '#ccddff'}}>
                              <TableCell align="left" style={{fontWeight: '700'}}>Drug (conc.)</TableCell>
                              <TableCell align="left" style={{fontWeight: '700'}}>mL to add to 250 mL fluid bag</TableCell>
                              <TableCell align="left" style={{fontWeight: '700'}}>Dose delivered at 1 mL/kg/hr</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left">Hydromorphone (2 mg/mL)</TableCell>
                              <TableCell align="left">2.5 mL</TableCell>
                              <TableCell align="left">0.02 mg/kg/hr</TableCell>
                            </TableRow>
                            <TableRow style={{backgroundColor: '#f2f2f2'}}>
                              <TableCell align="left">Lidocaine (20 mg/mL)</TableCell>
                              <TableCell align="left">18 mL</TableCell>
                              <TableCell align="left">1.5 mg/kg/hr</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left">Ketamine (100 mg/mL)</TableCell>
                              <TableCell align="left">0.3 mL</TableCell>
                              <TableCell align="left">0.12 mg/kg/hr</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                      </Grid>
                      :
                      <Grid item xs={12}>
                      <Paper className={classes.tablePaper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow style={{backgroundColor: '#ccddff'}}>
                              <TableCell align="left" style={{fontWeight: '700'}}>Drug (conc.)</TableCell>
                              <TableCell align="left" style={{fontWeight: '700'}}>mL to add to 250 mL fluid bag</TableCell>
                              <TableCell align="left" style={{fontWeight: '700'}}>Dose delivered at 1 mL/kg/hr</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left">Fentanyl (0.05 mg/mL)</TableCell>
                              <TableCell align="left">25 mL</TableCell>
                              <TableCell align="left">0.005 mg/kg/hr</TableCell>
                            </TableRow>
                            <TableRow style={{backgroundColor: '#f2f2f2'}}>
                              <TableCell align="left">Lidocaine (20 mg/mL)</TableCell>
                              <TableCell align="left">18 mL</TableCell>
                              <TableCell align="left">1.5 mg/kg/hr</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left">Ketamine (100 mg/mL)</TableCell>
                              <TableCell align="left">0.3 mL</TableCell>
                              <TableCell align="left">0.12 mg/kg/hr</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                      </Grid>
                    }
                    <Grid item xs={12}>
                    <p>Table modified from the 2017 <a href="https://ivapm.org/wp-content/uploads/2017/03/IVAPM-Dosage-Chart-2017.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>IVAPM Canine Quick Dosage Sheet</a></p>
                    </Grid>
                    </Grid>
                    </AccordionDetails>
                  </Accordion>
                  </li>
                  <li>
                  Consider 10-20 ug/kg Buprenorphine = {(this.state.weightkg*(0.01)).toFixed(2)} to {(this.state.weightkg*(0.02)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.01)/0.3)).toFixed(2)} to {((this.state.weightkg*(0.02)/0.3)).toFixed(2)} mL</span> of 0.3 mg/mL Buprenorphine which can be given IV, IM, SC or transmucosally.
                  </li>
                  </ul>
                  </Grid>
                  <Accordion>
                  <AccordionSummary style={{padding: '0px 16px 0px 0px'}} expandIcon={<ExpandMoreIcon />}>
                  <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                    <Checkbox
                    checked={this.state.checked}
                    onChange={this.setChecked}
                    color="primary"
                    />Consider fecal microbial transplant
                  </Grid>
                  </AccordionSummary>
                  <AccordionDetails>

                  <Grid item xs={12} style={{textAlign: 'left'}}>
                  <ul>
                  <li>
                    10 grams feces (from a healthy dog) in 10 mL saline administered per rectum 6-12 hours after admission was associated with faster resolution of diarrhea and shorter hospitalization.<sup>4</sup>
                  </li>
                  </ul>
                  </Grid>
                  </AccordionDetails>
                  </Accordion>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>CBC</span> q24h
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Comprehensive Chemistry</span> followed by albumin/electrolytes q24h and BG q6h
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  If BG is &le;60 mg/dL, bolus 0.5 to 1 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.5)).toFixed(2)} to {(this.state.weightkg*(1)).toFixed(2)} mL</span> 50% dextrose IV diluted 1:2 with saline
                  </li>
                  <li>
                  KCl supplementation or CRI may be necessary in cases of severe hypokalemia.
                  </li>
                  <li>
                  Colloid CRI (i.e. HES at 20 mL/kg/day) may be necessary in cases of worsening hypoalbuminemia.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Antibiotic therapy</span> for patients with leukopenia
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Unasyn (ampicillin-sulbactam) at 30 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*30).toFixed(2)} mg</span> IV q6-8h for this patient
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Fecal OP + Giardia antigen</span>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Empirical deworming</span> with Pyrantel Pamoate
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  10 mg/kg = {(this.state.weightkg*10).toFixed(2)} mg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)/(50)).toFixed(2)} mL</span> of 50 mg/mL Pyrantel Pamoate PO for this patient
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    /><span style={{fontWeight: '700'}}>Maropitant (Cerenia)</span>
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  1 mg/kg = {(this.state.weightkg*1).toFixed(2)} mg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/(10)).toFixed(2)} mL</span> of 10 mg/mL Maropitant (Cerenia) SC every 24 hours for this patient
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    />Consider <span style={{fontWeight: '700'}}>additional antiemetics</span>
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Ondansetron at 0.3-0.5 mg/kg = {(this.state.weightkg*(0.3)).toFixed(2)} to {(this.state.weightkg*(0.5)).toFixed(2)} mg  = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.3)/(2)).toFixed(2)} to {(this.state.weightkg*(0.5)/(2)).toFixed(2)} mL</span> of 2 mg/mL Ondansetron IV every 8 hours for this patient
                  </li>
                  <li>
                  Metoclopramide at 1-2 mg/kg/day = {(this.state.weightkg*(1)/24).toFixed(2)} to {(this.state.weightkg*(2)/24).toFixed(2)} mg/hr = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/(120)).toFixed(2)} to {(this.state.weightkg*(2)/(120)).toFixed(2)} mL/hr</span> of 5 mg/mL Metoclopramide for this patient
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <Checkbox
                    color="primary"
                    />Early <span style={{fontWeight: '700'}}>nutritional support</span>
                  </Grid>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Encouraged syringe feeding of 1 ml/kg = {(this.state.weightkg*1).toFixed(0)} mL of oral recovery liquid diet, Hill's a/d or Royal Canine Recovery as tolerated every 6 hours.
                  </li>
                  <li>
                  Consider a nasoesophageal or nasogastric feeding tube for patients that will not tolerate syringe feeding.
                  </li>
                  </ul>
                  </Grid>



                  <Grid item xs={12}>
                    <Divider style={{margin: '20px 0px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{margin: '20px 0px'}}/>
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                  Medications to consider for PO administration once vomiting resolves:
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <span style={{textDecoration: 'underline'}}>Maropitant (Cerenia)</span>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    Give INPUT tablet by mouth every 24 hours as needed to reduce nausea and improve appetite.  This medication has broad anti-inflammatory properties and may help your pet feel better.  Next dose due INPUT.
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                  <ul>
                  <li>
                    &ge; 2 mg/kg or &ge; {(this.state.weightkg*(2)/(1)).toFixed(2)} mg for this patient
                  </li>
                  <li>
                    Available as 16 mg, 24 mg, 60 mg, 160 mg tablets. Generally dosed &ge;2 mg/kg PO
                  </li>
                  </ul>
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <span style={{textDecoration: 'underline'}}>Metronidazole</span>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    Give INPUT by mouth every 12 hours for INPUT days.  This is an antibiotic that may help treat diarrhea.  Next dose due INPUT.
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                  <ul>
                  <li>
                    10-15 mg/kg = {(this.state.weightkg*(10)/(1)).toFixed(2)} to {(this.state.weightkg*(15)/(1)).toFixed(2)} mg for this patient.
                  </li>
                  <li>
                    Available as 250 mg or 500 mg tablets or 100 mg/mL oral suspension
                  </li>
                  </ul>
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <span style={{textDecoration: 'underline'}}>Capromorelin (Entyce)</span>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    Give INPUT mL by mouth every 24 hours as need to promote appetite.  This is an appetite stimulant.  Next dose due INPUT.
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                  <ul>
                  <li>
                    3 mg/kg = {(this.state.weightkg*(3)/(1)).toFixed(2)} mg = {(this.state.weightkg*(3)/(30)).toFixed(2)} mL of 30 mg/mL Entyce for this patient.
                  </li>
                  </ul>
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <span style={{textDecoration: 'underline'}}>Panacur (Fenbendazole)</span>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    Give INPUT by mouth every 24 hours for 5 days to treat intestinal parasites.  Next dose due INPUT.
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                  <ul>
                  <li>
                    50 mg/kg = {(this.state.weightkg*(50)/(1)).toFixed(1)} mg = {(this.state.weightkg*(50)/(100)).toFixed(1)} mL of 100 mg/mL Fenbendazole for this patient.  This is equivalent to {(this.state.weightkg*(50)/(222)).toFixed(1)} g of 22.2% granules.
                  </li>
                  </ul>
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left'}}>
                    <span style={{textDecoration: 'underline'}}>Probiotic</span>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'left'}}>
                  <ul>
                  <li>
                    Consider FortiFlora, Advita, Pro-Pectalin, Proviable, or any similar canine probiotic to support GI health.
                  </li>
                  </ul>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider style={{margin: '20px 0px'}}/>
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                    Informational Handouts:
                  </Grid>
                  <Grid item xs={12}>
                    <span className="LinkBlue"><a href="https://petparenthandouts.com/parvovirus" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Parvovirus</a> <span style={{fontWeight: "500"}}>from Pet Parent Handouts</span></span>
                  </Grid>
                  <Grid item xs={12}>
                    <span className="LinkBlue"><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951463" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Parvovirus in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></span>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider style={{margin: '20px 0px'}}/>
                  </Grid>

                  <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                    Discharge Note (Key Points):
                  </Grid>
                  <Grid item xs={12}>
                    <ul>
                    <li>
                      Parvovirus is contagious (can make other puppies and occasionally adult dogs sick), lasts for years in the environment where your dog has pooped or vomited, and will be shed in the poop of your dog for the next several weeks.  Vaccinate any unvaccinated dogs in your home and do not allow unvaccinated dogs on your property in areas where your pet has been.
                    </li>
                    <li>
                      Once recovered, continuing vaccinations and preventative care (including intestinal parasite, heartworm, and flea/tick/mite prevention) is especially important to prevent further health challenges.
                    </li>
                    <li>
                      Recheck promptly if not improving or worsening in any way.  Not eating, vomiting, low energy, diarrhea, difficulty breathing, or pain are all serious concerns that require prompt veterinary attention. Do not hesitate to call or recheck promptly if you have any concerns.
                    </li>
                    </ul>
                  </Grid>
                </Grid>

                }



                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                      References:
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <p><sup>1</sup> Sarpong et. al. Evaluation of mortality rate and predictors of outcome in dogs receiving outpatient treatment for parvoviral enteritis. JAVMA 2017.</p>

                        <p><sup>2</sup> Perley et. al. Retrospective evaluation of outpatient canine parvovirus treatment in a shelter-based low-cost urban clinic. JVECC 2020. (Penn follow-up to Colorado Outpatient Protocol)</p>

                        <p><sup>3</sup> Venn et. al. Evaluation of an outpatient protocol in the treatment of canine parvoviral enteritis. JVECC 2016. (Colorado Outpatient Protocol)</p>

                        <p><sup>4</sup> Pereira et. al. Fecal microbiota transplantation in puppies with canine parvovirus infection. JVIM 2018.</p>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <p>
                    This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
                  </p>
                  <p>
                    All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
                  </p>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center"}}>
                  <Link className="" to="/"><i className="material-icons home-button" style={{marginTop: "5px",}}>home</i></Link>
                </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }


};

export default withStyles(styles)(BlankQS);
