import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter as Router
} from 'react-router-dom'

import ScrollToTop from './ScrollToTop';

import QuickSheets from "./QuickSheets"

import CongestiveHeartFailure from "./disease/CongestiveHeartFailure"
import CardiopulmonaryResus from "./disease/CPR"
import Anaphylaxis from "./disease/Anaphylaxis"
import BlockedCat from "./disease/BlockedCat"
import Ivdd from "./disease/IVDD"
import Parvo from "./disease/Parvo"
import Dystocia from "./disease/Dystocia"
import Seizures from "./disease/Seizures"
import HeadTrauma from "./disease/HeadTrauma"
import Dka from "./disease/Dka"
import Fluids from "./disease/Fluids"
import Blank from "./disease/BlankTemplate"


const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route exact path='/' component={QuickSheets} />
        <Route exact path="/anaphylaxis" component={Anaphylaxis} />
        <Route exact path="/blocked-cat" component={BlockedCat} />
        <Route exact path="/cpr" component={CardiopulmonaryResus} />
        <Route exact path="/chf" component={CongestiveHeartFailure} />
        <Route exact path="/dka" component={Dka} />

        <Route exact path="/ivdd" component={Ivdd} />
        <Route exact path="/parvo" component={Parvo} />
        <Route exact path="/dystocia" component={Dystocia} />
        <Route exact path="/head-trauma" component={HeadTrauma} />
        <Route exact path="/fluids" component={Fluids} />


        <Route exact path="/seizures" component={Seizures} />

        <Route exact path="/blank" component={Blank} />


        <Route render={() => <QuickSheets/>} />
      </Switch>
    </ScrollToTop>
  </Router>
)

export default Routes
